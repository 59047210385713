import { AuctionHouseListProps, SelectOption } from 'Types/global.types.ts'
import { TFunction } from 'i18next'
// import { Country } from 'country-state-city'

export const API_URL: string = import.meta.env.VITE_API_URL as string
export const AUTH_BASE_API_URL = `${API_URL}/auth`
export const USER_BASE_API_URL = `${API_URL}/user`
export const SHIPPING_ADDRESS = `${API_URL}/shipping-address`
export const BUYER = `${API_URL}/buyer`
export const SELLER = `${API_URL}/seller`
export const NOTIFICATION_BASE_API_URL = `${API_URL}/notifications`
export const BLACKLIST_BASE_API_URL = `${API_URL}/blacklist`
export const REGISTRATION_BASE_API_URL = `${API_URL}/registration`
export const VIN_BASE_API_URL = `${API_URL}/vin`
export const CERTIFICATE_BASE_API_URL = `${API_URL}/certificate`
export const PURCHASE_BASE_API_URL = `${API_URL}/purchase`
export const USER_STORAGE_PLACE = `${API_URL}/storage-place`
export const USER_CUSTOMS_CROSSING = `${API_URL}/customs-crossing`
export const CONTACT_BASE_API_URL = `${API_URL}/contact`

/**
 * Auth API
 */
export const AUTH_API: {
  SIGN_IN_URL: string
  SIGN_UP_URL: string
  VERIFY_URL: string
  FORGOT_PASSWORD: string
  UPDATE_PASSWORD: string
  RESEND_EMAIL: string
} = {
  SIGN_IN_URL: `${AUTH_BASE_API_URL}/signin`,
  SIGN_UP_URL: `${AUTH_BASE_API_URL}/signup`,
  VERIFY_URL: `${AUTH_BASE_API_URL}/verify`,
  FORGOT_PASSWORD: `${AUTH_BASE_API_URL}/forgot-password`,
  UPDATE_PASSWORD: `${AUTH_BASE_API_URL}/update-password`,
  RESEND_EMAIL: `${AUTH_BASE_API_URL}/resend-email`,
}

export const AUTH_ERRORS: { UNEXPECTED: string; AXIOS: string } = {
  UNEXPECTED: 'An unexpected error occurred',
  AXIOS: 'Axios error: ',
}

/**
 * User API
 */
export const USER_API: {
  PROFILE_URL: string
  ONBOARDING_URL: string
  UPDATE_USER_PASSWORD: string
  SET_PRIMARY_SHIPPING_ADDRESS: string
} = {
  PROFILE_URL: `${USER_BASE_API_URL}/profile`,
  ONBOARDING_URL: `${USER_BASE_API_URL}/finish-onboarding`,
  UPDATE_USER_PASSWORD: `${USER_BASE_API_URL}/update-password`,
  SET_PRIMARY_SHIPPING_ADDRESS: `${USER_BASE_API_URL}/shipping-address`,
}

export const BUYER_API = {
  BUYER_SEARCH: `${BUYER}/search`,
}

export const SELLER_API = {
  SELLER_SEARCH: `${SELLER}/search`,
  SELLER_EORI: `${SELLER}/eori`,
}

export const REGISTRATION_API = {
  REGISTRATION_SEARCH: `${REGISTRATION_BASE_API_URL}/search`,
}

export const VIN_API = {
  VIN_SEARCH: `${VIN_BASE_API_URL}/search`,
}

export const CERTIFICATE_API = {
  CERTIFICATE_SEARCH: `${CERTIFICATE_BASE_API_URL}/search`,
}

/**
 * Notification API
 */
export const NOTIFICATION_API = {
  ROOT: NOTIFICATION_BASE_API_URL,
  UNREAD_COUNT: `${NOTIFICATION_BASE_API_URL}/unread-count`,
  READ_ALL: `${NOTIFICATION_BASE_API_URL}/read-all`,
  CREATE_EXPIRED: `${NOTIFICATION_BASE_API_URL}/create-expired`,
}

/**
 * Download API
 */
export const DOWNLOAD_API = {
  GET_FILE: `${API_URL}/download`,
}

export const DHL_API = {
  LIST: `${API_URL}/dhl`,
}

export const PAYMENT_API = {
  CERTIFICATE: `${API_URL}/certificate`,
  PURCHASE: `${API_URL}/purchase`,
  DHL: `${API_URL}/dhl`,
}

export const USER_TYPE = {
  physical: 'PHYSICAL',
  legal: 'LEGAL',
  forwarder: 'FORWARDER',
  super: 'SUPER',
  regular: 'REGULAR',
}

export const USER_ROLES = ['PHYSICAL', 'LEGAL', 'FORWARDER']

export const ADMIN_ROLES = ['SUPER', 'REGULAR']

export const MAX_UPLOAD_FILE = 50

export const MAX_CHARACTERS = 50
export const MIN_CHARACTERS = 8
export const MIN_REQUIRED_SEARCH_CHARACTERS = 3

export const AUCTION_HOUSE_LIST: Array<AuctionHouseListProps> = [
  {
    id: 'alcopa-auction-house',
    auctionHouseName: 'Alcopa',
    auctionHousePhone: '+33970719752',
    auctionHouseEmail: 'contact@alcopa-auction.fr',

    auctionHouseEori: '111222333444',
    auctionHouseAddress: 'test',
    auctionHouseStreet: 'test',
    auctionHousePostalCode: '21000',
    auctionHouseCity: 'test',
    auctionHouseCountry: 'test',

    description: 'Alcopa auction house',
    imageUrl: '/auction-houses/auction-house-alcopa.png',
  },
  {
    id: 'vpauto-auction-house',
    auctionHouseName: 'Vpauto',
    auctionHousePhone: '+38163111111',
    auctionHouseEmail: 'vpauto@gmail.com',

    auctionHouseEori: '111222333444',
    auctionHouseAddress: 'test',
    auctionHouseStreet: 'test',
    auctionHousePostalCode: '21000',
    auctionHouseCity: 'test',
    auctionHouseCountry: 'test',

    description: 'VPauto auction house',
    imageUrl: '/auction-houses/auction-house-vpcar.png',
  },
]

/**
 * Payment statuses
 */
export const CertificateState = {
  NotProcessed: 'NOT_PROCESSED',
  InProgress: 'IN_PROGRESS',
  Processed: 'PROCESSED',
}

export const PaymentState = {
  NotPaid: 'NOT_PAID',
  Failed: 'FAILED',
  Paid: 'PAID',
  Monthly: 'MONTHLY',
  Monthly_generated: 'MONTHLY_GENERATED',
}

export const PurchaseState = {
  Active: 'ACTIVE',
  Finished: 'FINISHED',
}

export const AdminPurchaseState = {
  Active: 'ACTIVE',
  Finished: 'FINISHED',
  Failed: 'FAILED',
}

export const AdminOrderState = {
  NotAssigned: 'NOT ASSIGNED',
  InProgress: 'IN PROGRESS',
  InReview: 'IN REVIEW',
  Completed: 'COMPLETED',
}

export const WorkStatus = {
  Pending: 'PENDING',
  ReadyForReview: 'READY FOR REVIEW',
  InProgress: 'IN PROGRESS',
  InReview: 'IN REVIEW',
  Completed: 'COMPLETED',
}

export const mapAdminRoleName: { [key: string]: string } = {
  SUPER: 'Super admin',
  REGULAR: 'Admin',
}

export const PAYMENT_TYPE = {
  prepaid: 'PREPAID',
  postpaid: 'POSTPAID',
  card: 'CARD',
}

export const PAYMENT_TYPE_MAP = [
  {
    label: 'Prepaid',
    value: 'PREPAID',
  },
  {
    label: 'Postpaid',
    value: 'POSTPAID',
  },
  {
    label: 'Credit card',
    value: 'CARD',
  },
]

export const getPaymentTypeMapTranslation = (t: TFunction) => [
  {
    label: t('translation:common.label.prepaid'),
    value: 'PREPAID',
  },
  {
    label: t('translation:common.label.postpaid'),
    value: 'POSTPAID',
  },
  {
    label: t('translation:common.label.credit_card'),
    value: 'CARD',
  },
]

export const mapUserTypes = {
  PHYSICAL: 'Physical',
  LEGAL: 'Legal',
  FORWARDER: 'Forwarder',
}

export const getUserTypesTranslate = (t: TFunction) => ({
  PHYSICAL: t('translation:common.label.physical'),
  LEGAL: t('translation:common.label.legal'),
  FORWARDER: t('translation:common.label.forwarder'),
})

export const NOMENCLATURE_OPTIONS = [
  {
    value: '8703319000',
    label: "Cylindrée n'excédant pas 1500 cm³ en diesel - 8703319000",
  },
  {
    value: '8703329000',
    label: "Cylindrée excédant 1500 cm³ mais n'excédant pas 2 500 cm³ diesel - 8703329000",
  },
  {
    value: '8703229000',
    label: "Cylindrée excédant 1000 cm³ mais n'excédant pas 1500 cm³ essence - 8703229000",
  },
  {
    value: '8703239000',
    label: "Cylindrée excédant 1500 cm³ mais n'excédant pas 3 000 cm³ essence - 8703239000",
  },
  {
    value: '8703809000',
    label: 'Véhicules électriques - 8703809000',
  },
  {
    value: '8702101900',
    label:
      'Véhicules automobiles pour le transport de 10 personnes ou plus, diesel and semi-diesel excédant 2500 cm³ - 8702101900',
  },
  {
    value: '8701219000',
    label: 'Tracteurs routiers pour semi-remorques - 8701219000',
  },
  {
    value: '8701931000',
    label: 'Tracteurs agricoles occasion - 8701931000',
  },
  {
    value: '8716400000',
    label: 'Remorques and semi-remorques pour le transport des marchandises - 8716400000',
  },
  {
    value: '8711100000',
    label: "Moteur à piston alternatif, d'une cylindrée n'excédant pas 50 cm³ - 8711100000",
  },
  {
    value: '8711209200',
    label: "Excédant 50 cm³ mais n'excédant pas 125 cm³ - 8711209200",
  },
  {
    value: '8711209800',
    label: "Excédant 125 cm³ but n'excédant pas 250 cm³ - 8711209800",
  },
  {
    value: '8711301000',
    label: "D'une cylindrée excédant 250 cm³ but n'excédant pas 380 cm³ - 8711301000",
  },
  {
    value: '8711309000',
    label: "D'une cylindrée excédant 380 cm³ but n'excédant pas 500 cm³ - 8711309000",
  },
  {
    value: '8711400000',
    label: "Moteur à piston alternatif d'une cylindrée excédant 500 cm³ but n'excédant pas 800 cm³ - 8711400000",
  },
  {
    value: '8704211000',
    label: "Véhicule pour transport de marchandise jusqu'à 5 tonnes - 8704211000",
  },
  {
    value: '8704213900',
    label: 'Véhicules automobiles pour le transport de marchandises excédant 2|500|cm$3 - 8704213900',
  },
  {
    value: '8703241000',
    label: 'Véhicules neufs - 8703241000',
  },
  {
    value: '8427101000',
    label: 'Fenwick - 8427101000',
  },
  {
    value: '8702901900',
    label: 'AUTOBUS - 8702901900',
  },
  {
    value: '8479100000',
    label: 'MACHINE BTP - 8479100000',
  },
  {
    value: '8436809000',
    label: 'MACHINE AGRICOLE - 8436809000',
  },
]

export const nomenclatureOptions = (t: TFunction): SelectOption[] => [
  {
    value: '8703319000',
    label: t('translation:common.nomenclature.n_8703319000'), // Cylindrée n'excédant pas 1500 cm³ en diesel - 8703319000
  },
  {
    value: '8703329000',
    label: t('translation:common.nomenclature.n_8703329000'), //"Cylindrée excédant 1500 cm³ mais n'excédant pas 2 500 cm³ diesel - 8703329000",
  },
  {
    value: '8703229000',
    label: t('translation:common.nomenclature.n_8703229000'), // "Cylindrée excédant 1000 cm³ mais n'excédant pas 1500 cm³ essence - 8703229000",
  },
  {
    value: '8703239000',
    label: t('translation:common.nomenclature.n_8703239000'), // "Cylindrée excédant 1500 cm³ mais n'excédant pas 3 000 cm³ essence - 8703239000",
  },
  {
    value: '8703809000',
    label: t('translation:common.nomenclature.n_8703809000'), // 'Véhicules électriques - 8703809000',
  },
  {
    value: '8702101900',
    label: t('translation:common.nomenclature.n_8702101900'), // 'Véhicules automobiles pour le transport de 10 personnes ou plus, diesel and semi-diesel excédant 2500 cm³ - 8702101900',
  },
  {
    value: '8701219000',
    label: t('translation:common.nomenclature.n_8701219000'), //'Tracteurs routiers pour semi-remorques - 8701219000',
  },
  {
    value: '8701931000',
    label: t('translation:common.nomenclature.n_8701931000'), //'Tracteurs agricoles occasion - 8701931000',
  },
  {
    value: '8716400000',
    label: t('translation:common.nomenclature.n_8716400000'), //'Remorques and semi-remorques pour le transport des marchandises - 8716400000',
  },
  {
    value: '8711100000',
    label: t('translation:common.nomenclature.n_8711100000'), // "Moteur à piston alternatif, d'une cylindrée n'excédant pas 50 cm³ - 8711100000",
  },
  {
    value: '8711209200',
    label: t('translation:common.nomenclature.n_8711209200'), // "Excédant 50 cm³ mais n'excédant pas 125 cm³ - 8711209200",
  },
  {
    value: '8711209800',
    label: t('translation:common.nomenclature.n_8711209800'), // "Excédant 125 cm³ but n'excédant pas 250 cm³ - 8711209800",
  },
  {
    value: '8711301000',
    label: t('translation:common.nomenclature.n_8711301000'), // "D'une cylindrée excédant 250 cm³ but n'excédant pas 380 cm³ - 8711301000",
  },
  {
    value: '8711309000',
    label: t('translation:common.nomenclature.n_8711309000'), // "D'une cylindrée excédant 380 cm³ but n'excédant pas 500 cm³ - 8711309000",
  },
  {
    value: '8711400000',
    label: t('translation:common.nomenclature.n_8711400000'), // "Moteur à piston alternatif d'une cylindrée excédant 500 cm³ but n'excédant pas 800 cm³ - 8711400000",
  },
  {
    value: '8704211000',
    label: t('translation:common.nomenclature.n_8704211000'), // "Véhicule pour transport de marchandise jusqu'à 5 tonnes - 8704211000",
  },
  {
    value: '8704213900',
    label: t('translation:common.nomenclature.n_8704213900'), // 'Véhicules automobiles pour le transport de marchandises excédant 2|500|cm$3 - 8704213900',
  },
  {
    value: '8703241000',
    label: t('translation:common.nomenclature.n_8703241000'), // 'Véhicules neufs - 8703241000',
  },
  {
    value: '8427101000',
    label: t('translation:common.nomenclature.n_8427101000'), // 'Fenwick - 8427101000',
  },
  {
    value: '8702901900',
    label: t('translation:common.nomenclature.n_8702901900'), // 'AUTOBUS - 8702901900',
  },
  {
    value: '8479100000',
    label: t('translation:common.nomenclature.n_8479100000'), // 'MACHINE BTP - 8479100000',
  },
  {
    value: '8436809000',
    label: t('translation:common.nomenclature.n_8436809000'), // 'MACHINE AGRICOLE - 8436809000',
  },
]

export const getLanguageList = (isAdmin: boolean) => [
  {
    label: 'EN',
    value: 'en',
  },
  {
    label: 'FR',
    value: 'fr',
  },
  ...(!isAdmin
    ? [
        {
          label: 'SR',
          value: 'sr',
        },
        {
          label: 'RU',
          value: 'ru',
        },
      ]
    : []),
]

// const countries = Country.getAllCountries()
// export const countryList = countries.map((country) => ({
//   value: country.isoCode,
//   label: country.name,
// }))

export const HOME_PAGE_ROUTE = '/landing/home-page'

export const EXPORTATION_COUNTRIES = [
  {
    label: 'Serbia',
    value: 'RS',
  },
  {
    label: 'Bosnia and Herzegovina',
    value: 'BA',
  },
  {
    label: 'Kosovo',
    value: 'XK',
  },
  {
    label: 'Macedonia',
    value: 'MK',
  },
  {
    label: 'Montenegro',
    value: 'ME',
  },
  {
    label: 'Tunisia',
    value: 'TN',
  },
  {
    label: 'Algeria',
    value: 'DZ',
  },
  {
    label: 'Morocco',
    value: 'MA',
  },
]
