import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { isNull as _isNull } from 'lodash'

import { PaginationProps, SellerSliceProps, SellerType } from 'Types/global.types.ts'
import { getSeller, addSeller, searchSeller } from 'Api/seller.service.ts'

const initialState = {
  initialSellers: [],
  sellers: [],
  loading: false,
  pageIndex: 0,
  pageSize: 10,
} as SellerSliceProps

const sellerSlice = createSlice({
  name: 'seller',
  initialState,
  reducers: {
    setSellerPageSize: (state, action: PayloadAction<PaginationProps>) => {
      state.pageIndex = action.payload.pageIndex
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getSeller.fulfilled, (state, action) => {
        if (action.payload) {
          // @ts-ignore
          state.sellers = action.payload.sellers
          state.total = action.payload.total
          // @ts-ignore
          state.initialSellers = action.payload.sellers
        }
        state.loading = false
      })
      .addCase(getSeller.pending, (state) => {
        state.loading = true
      })
      .addCase(getSeller.rejected, (state) => {
        state.loading = false
      })
      .addCase(addSeller.fulfilled, (state, action) => {
        if (action.payload) {
          state.sellers.unshift(action.payload)
          state.initialSellers.unshift(action.payload)
          state.total += 1
        }
      })
      .addCase(searchSeller.fulfilled, (state, action: PayloadAction<SellerType>) => {
        if (!_isNull(action.payload)) {
          // @ts-ignore
          state.sellers = action.payload
        } else {
          state.sellers = [...state.initialSellers]
        }
      })
  },
})

const { actions } = sellerSlice
export const { setSellerPageSize } = actions
export default sellerSlice.reducer
