import { isNull as _isNull } from 'lodash'
import { TFunction } from 'i18next'

import { UserTypes } from 'Types/user.types.ts'

import { getTranslateMonthNames } from 'Constants/Global.admin.constants.ts'

/**
 * Convert Array list into Object list
 * @param arrList
 */
export const formatArrayToObject = (arrList: Array<{ id: string; value: string }>) => {
  const results: { [key: string]: string } = {}

  for (const obj of arrList) {
    results[obj.id] = obj.value
  }

  return results
}

export const extractInvoiceBillingInformation = (user: UserTypes, t: TFunction) => {
  const { companyName, firstName, lastName, country, city, phone, postalCode, streetName, streetNumber } = user || {}

  return [
    {
      label: t('translation:common.label.company_name'),
      value: companyName,
    },
    {
      label: t('translation:common.label.first_name'),
      value: firstName,
    },
    {
      label: t('translation:common.label.last_name'),
      value: lastName,
    },
    {
      label: t('translation:form.placeholder.address'),
      value: `${streetName}, ${streetNumber}`,
    },
    {
      label: t('translation:form.placeholder.city'),
      value: city,
    },
    {
      label: t('translation:form.placeholder.postal_code'),
      value: postalCode,
    },
    {
      label: t('translation:form.placeholder.country'),
      value: country,
    },
    {
      label: t('translation:form.placeholder.phone'),
      value: phone,
    },
  ]
}

export const formatCommandId = (command: string) => {
  if (_isNull(command) || typeof command !== 'string') {
    return '-'
  }

  return `${command?.slice(0, 3)}...${command?.slice(-6)}`
}

export const extractMinMaxDateFromMonth = (year: string, month: string) => {
  if (typeof year === 'string' && typeof month === 'string') {
    const fullYear = parseInt(year)
    const ordinalMonthNumber = parseInt(month)

    const minDate = new Date(fullYear, ordinalMonthNumber - 1, 1)
    const maxDate = new Date(fullYear, ordinalMonthNumber, 0)

    return {
      minDate,
      maxDate,
    }
  }

  return {
    minDate: null,
    maxDate: null,
  }
}

export const getMonthName = (monthNumber: number) => {
  if (typeof monthNumber === 'number') {
    const date = new Date()
    date.setMonth(monthNumber - 1)

    return date.toLocaleString('en-US', {
      month: 'long',
    })
  }

  console.error('monthNumber must be a number')
}

export const generateYearOptions = (limit: number) => {
  const currentYear = new Date().getFullYear()
  const yearRange = []

  for (let i = currentYear - limit; i <= currentYear; i++) {
    yearRange.unshift({ label: i.toString(), value: i.toString() })
  }

  return yearRange
}

export const generateMonthOptions = (t: TFunction) => {
  const monthNames = getTranslateMonthNames(t)
  return monthNames.map((monthName, index) => ({
    label: monthName,
    value: `${index + 1}`,
  }))
}

export const formatCompactNumber = (number: number) => {
  if (number < 1000) {
    return number
  } else if (number >= 1000 && number < 1_000_000) {
    return (number / 1000).toFixed(1).replace(/\.0$/, '') + 'K'
  } else if (number >= 1_000_000 && number < 1_000_000_000) {
    return (number / 1_000_000).toFixed(1).replace(/\.0$/, '') + 'M'
  } else if (number >= 1_000_000_000 && number < 1_000_000_000_000) {
    return (number / 1_000_000_000).toFixed(1).replace(/\.0$/, '') + 'B'
  } else if (number >= 1_000_000_000_000 && number < 1_000_000_000_000_000) {
    return (number / 1_000_000_000_000).toFixed(1).replace(/\.0$/, '') + 'T'
  }
}

export const formatAbbreviateCurrency = (number: number) => {
  const value = formatCompactNumber(number)
  return `€${value}`
}
