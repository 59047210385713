import { FormikValues } from 'formik'
import axios, { AxiosResponse } from 'axios'
import { createAsyncThunk } from '@reduxjs/toolkit'

import { getAccessToken } from 'Utils/auth.util.ts'

import { PaginationProps, SellerEoriResponse, SellerListResponse, SellerResponse } from 'Types/global.types.ts'
import { MIN_REQUIRED_SEARCH_CHARACTERS, SELLER, SELLER_API } from 'Constants/Global.constants.ts'
import { toast } from 'react-toastify'
import { handleError } from 'Helpers/errors.helper.ts'

/**
 * Get all sellers
 */
export const getSeller = createAsyncThunk('seller/getSeller', async ({ pageSize, pageIndex }: PaginationProps) => {
  const token = getAccessToken()
  const url = `${SELLER}?page=${pageIndex}&limit=${pageSize}`
  const config = {
    headers: {
      Authorization: token,
    },
  }

  try {
    const { data }: AxiosResponse<SellerListResponse> = await axios.get(url, config)
    return {
      sellers: data.data,
      total: data.total,
    }
  } catch (error) {
    handleError(error, true)
  }
})

/**
 * Async load of seller list for Async select dropdown
 * @param pageIndex
 * @param pageSize
 */
export const loadSellerList = async ({
  pageIndex,
  pageSize,
}: {
  pageIndex: number
  pageSize: number
  param: string
}) => {
  const token = getAccessToken()
  const url = `${SELLER}?page=${pageIndex}&limit=${pageSize}`
  const config = {
    headers: {
      Authorization: token,
    },
  }

  try {
    const { data }: AxiosResponse<SellerListResponse> = await axios.get(url, config)
    return {
      list: data.data,
      total: data.total,
    }
  } catch (error) {
    handleError(error, true)
  }
}

/**
 * Create a new seller
 */
export const addSeller = createAsyncThunk('seller/add', async (options: FormikValues) => {
  const token = getAccessToken()

  const config = {
    headers: {
      Authorization: token,
      'Content-Type': 'multipart/form-data',
    },
  }

  try {
    const { data }: AxiosResponse<SellerResponse> = await axios.post(SELLER, options, config)
    return data.data
  } catch (error: unknown) {
    if (axios.isAxiosError(error)) {
      if (error.response) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        const errorMessage: string = error.response.data?.error.message
        toast.error(errorMessage)
        throw error
      } else if (error.request) {
        console.error('REQUEST ERROR', error.request)
      } else {
        throw error.message
      }
    } else {
      throw error
    }
  }
})

/**
 * Search sellers by keyword
 */
export const searchSeller = createAsyncThunk('seller/search', async (keyword: string) => {
  const url = `${SELLER_API.SELLER_SEARCH}?param=${keyword}`
  const token = getAccessToken()
  const charLength = keyword.length

  const config = {
    headers: {
      Authorization: token,
    },
  }

  try {
    if (charLength >= MIN_REQUIRED_SEARCH_CHARACTERS) {
      const { data }: AxiosResponse<SellerResponse> = await axios.get(url, config)
      return data.data
    } else {
      return null
    }
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw new Error(error.message)
    } else {
      throw new Error('An unexpected error occurred')
    }
  }
})

/**
 * Get EORI in order to fetch seller data
 * @param eoriNumber
 */
export const getEori = async (eoriNumber: string) => {
  const url = `${SELLER_API.SELLER_EORI}?eoriNumber=${eoriNumber}`
  const token = getAccessToken()

  const config = {
    headers: {
      Authorization: token,
    },
  }

  const { data }: AxiosResponse<SellerEoriResponse> = await axios.get(url, config)
  return data.data
}
