import { createAsyncThunk } from '@reduxjs/toolkit'
import axios, { AxiosResponse } from 'axios'

import { BuyerResponse } from 'Types/global.types.ts'

import { ADMIN_API_URL } from 'Constants/Global.admin.constants.ts'

import { getAccessToken } from 'Utils/auth.util.ts'
import { handleError } from 'Helpers/errors.helper.ts'

/**
 * Update Admin buyer
 */
export const updateAdminBuyer = createAsyncThunk(
  'admin/updateAdminBuyer',
  async (payload: { id: string; options: FormData }) => {
    const { id, options } = payload
    const token = getAccessToken()

    const url = `${ADMIN_API_URL.BUYER.ROOT}/${id}`
    const config = {
      headers: {
        Authorization: token,
      },
    }

    try {
      const { data }: AxiosResponse<BuyerResponse> = await axios.patch(url, options, config)
      return {
        id,
        buyer: data.data,
      }
    } catch (error) {
      handleError(error, true)
    }
  }
)
