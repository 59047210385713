import { createAsyncThunk } from '@reduxjs/toolkit'
import axios, { AxiosResponse } from 'axios'

import { CertificateResponseType, SellerEoriResponse, SellerListResponse, SellerResponse } from 'Types/global.types.ts'

import { ADMIN_API_URL } from 'Constants/Global.admin.constants.ts'

import { getAccessToken } from 'Utils/auth.util.ts'
import { handleError } from 'Helpers/errors.helper.ts'

/**
 * Get all admin sellers
 */
export const getAdminSellers = createAsyncThunk(
  'admin/getAdminSellers',
  async ({ pageIndex, pageSize }: { pageIndex: number; pageSize: number }) => {
    const token = getAccessToken()
    const url = `${ADMIN_API_URL.SELLER.ROOT}?page=${pageIndex}&limit=${pageSize}`
    const config = {
      headers: {
        Authorization: token,
      },
    }

    try {
      const { data }: AxiosResponse<SellerListResponse> = await axios.get(url, config)
      return {
        adminSellers: data.data,
        total: data.total,
      }
    } catch (error) {
      handleError(error, true)
    }
  }
)

export const searchAdminSellers = createAsyncThunk('admin/searchAdminSellers', async (keyword: string) => {
  const token = getAccessToken()
  const url = `${ADMIN_API_URL.SELLER.SEARCH}?param=${keyword}`
  const config = {
    headers: {
      Authorization: token,
    },
  }

  try {
    const { data }: AxiosResponse<SellerListResponse> = await axios.get(url, config)
    return {
      adminSellers: data.data,
      total: data.total,
    }
  } catch (error) {
    handleError(error, true)
  }
})

export const setSellerToOrder = createAsyncThunk(
  'admin/setSellerToOrder',
  async ({ sellerId, orderId }: { sellerId: string; orderId: string }) => {
    const token = getAccessToken()
    const url = `${ADMIN_API_URL.ORDER.ROOT}/${orderId}/seller`
    const config = {
      headers: {
        Authorization: token,
      },
    }

    const requestPayload = {
      sellerId,
    }

    try {
      const { data }: AxiosResponse<CertificateResponseType> = await axios.patch(url, requestPayload, config)
      return {
        order: data.data,
      }
    } catch (error) {
      handleError(error, true)
    }
  }
)

/**
 * Update admin seller
 */
export const updateAdminSeller = createAsyncThunk(
  'admin/updateAdminSeller',
  async (payload: { id: string; options: FormData }) => {
    const { id, options } = payload
    const token = getAccessToken()

    const url = `${ADMIN_API_URL.SELLER.ROOT}/${id}`
    const config = {
      headers: {
        Authorization: token,
      },
    }

    try {
      const { data }: AxiosResponse<SellerResponse> = await axios.patch(url, options, config)
      return {
        id,
        seller: data.data,
      }
    } catch (error) {
      handleError(error, true)
    }
  }
)

export const addAdminSeller = createAsyncThunk('admin/addAdminSeller', async (options: FormData) => {
  const url = ADMIN_API_URL.SELLER.ROOT

  const token = getAccessToken()
  const config = {
    headers: {
      Authorization: token,
    },
  }

  try {
    const { data }: AxiosResponse<SellerResponse> = await axios.post(url, options, config)
    return {
      seller: data.data,
    }
  } catch (error) {
    handleError(error, true)
  }
})

/**
 * Update status of admin seller
 */
export const updateAdminSellerStatus = createAsyncThunk(
  'admin/updateAdminSellerStatus',
  async ({ sellerId, status }: { sellerId: string; status: 'approve' | 'decline' }) => {
    const url = `${ADMIN_API_URL.SELLER.ROOT}/${sellerId}/${status}`
    const token = getAccessToken()
    const config = {
      headers: {
        Authorization: token,
      },
    }

    try {
      const { data }: AxiosResponse<SellerResponse> = await axios.patch(url, null, config)
      return {
        seller: data.data,
      }
    } catch (error) {
      handleError(error, true)
    }
  }
)

export const toggleCheckSeller = createAsyncThunk(
  'admin/toggleCheckSeller',
  async ({ sellerId, checked }: { sellerId: string; checked: 'uncheck' | 'check' }) => {
    const url = `${ADMIN_API_URL.SELLER.ROOT}/${sellerId}/${checked}`
    const token = getAccessToken()
    const config = {
      headers: {
        Authorization: token,
      },
    }

    try {
      const { data }: AxiosResponse<SellerResponse> = await axios.put(url, null, config)
      return {
        seller: data.data,
      }
    } catch (error) {
      handleError(error, true)
    }
  }
)

export const getAdminEori = async (eoriNumber: string) => {
  const url = `${ADMIN_API_URL.SELLER.EORI}?eoriNumber=${eoriNumber}`
  const token = getAccessToken()

  const config = {
    headers: {
      Authorization: token,
    },
  }

  const { data }: AxiosResponse<SellerEoriResponse> = await axios.get(url, config)
  return data.data
}
